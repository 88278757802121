import { apiDeviceData, apiDeviceSession, apiNotification, firebaseKeyApi, apiVideoData, apiDeviceDataDownload } from './Consts';
// apiDeviceDetail
const firebaseKey = "key=AAAAsY5xkgo:APA91bFLPuadsaGsEHrrbTvoWFjSruOuqMdozKU-K7B5XhuiazTL11vKaVMUlB8QQI4-dvxQudmz3LoJ2oVGLE-5qDVrpnfarxd1MLKatg3wCvr_9eDSweI5F9fAqIloFw-TATiznSKL";
const projectId = "762599019018"

export var deviceDataAPI = ({ page, limit, sortField, sortOrder, search }) => {
    const url = `${apiDeviceData}?page=${page}&limit=${limit}${sortField ? `&sortField=${sortField}` : ''}${sortOrder ? `&sortOrder=${sortOrder}` : ''}${search ? `&search=${encodeURIComponent(search)}` : ''}`;
    return fetch(url, {
        method: "GET",
        // body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(res => res.json())
        .then(
            (result) => {
                console.log(">result",result)
                return result.result;
            },
            (error) => {
                return error;
            }
        )
}
export var deviceDataAPIForDownload = () => {
    const url = `${apiDeviceDataDownload}`;
    return fetch(url, {
        method: "GET",
        // body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(res => res.json())
        .then(
            (result) => {
                console.log(">result: apiDeviceDataDownload",result)
                return result.result;
            },
            (error) => {
                return error;
            }
        )
}

export var exportdeviceDataAPI = () => {
    return fetch(apiDeviceData + '/export-data', {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result.result;
            },
            (error) => {
                return error;
            }
        )
}



export var deviceSessionAPI = (id) => {
    return fetch(apiDeviceSession + id, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result.result;
            },
            (error) => {
                return error;
            }
        )
}

export var getColorDataByEmail = (apiBody) => {
    return fetch(`${apiDeviceData}/device-session`, {
        method: "POST",
        body: JSON.stringify(apiBody),
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}
export var removeDeviceSession = (id) => {
    return fetch(apiDeviceData + `/${id}`, {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json',
            // [tokenHeader]: getUserToken(),
            // [orgTokenHeader]: getOrgUserToken()
        }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}
export var deviceNotificationAPI = (data) => {
    return fetch(apiNotification, {
        method: "GET",
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var deviceEmailsAPI = (searchQuery) => {
    return fetch(`${apiNotification}/emails?searchQuery=${searchQuery}`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var sendNotificationOnEmails = (data) => {
    return fetch(`${apiNotification}/send-notification-token`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var sendNotificationApi1 = () => {
    var data = {
        "operation": "create",
        "notification_key_name": 'Date.now()',
        "registration_ids": ["fzkkY2ucTjqxBYgQKmkr3m:APA91bFCgu0to9gKnqJcGme97Ryk2HSMx-O8cFSmuVDhSGjG8F9Mggryfid3emFfzsXAuG5UzKlPPgUaEq_D5HgeF3rtv-oDM3pxG9l6JOdquhPZtg8BYwP9ZE82SESktoptufDXPYjM", "ezpycSRuR-WdubwnWAKRYp:APA91bG--jH1gTpfW76ij6zWdg0n7-epwPDZZTS9l1SA39DR27QjGZDtV6VIYw1iyoRg9ER2Nh4uPN_1Yh8IDlFNytWPEoFfxqsWs4YyNDbqIlsh-gWlUAwwub074BJjfeirG8mBV7R3"]
    }
    return fetch(firebaseKeyApi, {
        method: "POST",
        mode: 'no-cors',
        body: JSON.stringify(data),
        headers: {
            'project_id': projectId,
            'Authorization': firebaseKey,
            'Content-Type': 'application/json'
        }
    })
        .then(res => res.json())
        .then(
            (result) => {
                // alert(result)
                return result;
            },
            (error) => {
                // alert(error)
                return error;
            }
        )
}

export var sendNotificationApi = (data) => {
    console.log("data------", data);
    return fetch(apiNotification + '/send', {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            // 'Authorization': firebaseKey,
            'Content-Type': 'application/json',
        }
    })
        .then(res => res.json())
        .then(
            (result) => {
                // alert(result)
                return result;
            },
            (error) => {
                alert(error)
                return error;
            }
        )
}

export var getVideoData = (data) => {
    return fetch(apiVideoData + "/get-video-data", {
        method: "POST",
        body: data
    }).then(res => res.json())
        .then((data) => { return data })
        .catch((err) => console.log(err));
}
export var uploadVideo = (data) => {
    return fetch(apiVideoData + "/upload", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            // 'Authorization': firebaseKey,
            'Content-Type': 'application/json',
        }
    }).then(res => res.json())
        .then((data) => { return data })
        .catch((err) => console.log(err));
}
export var getAllVideos = () => {
    return fetch(apiVideoData, {
        method: "GET",
        headers: {
            // 'Authorization': firebaseKey,
            'Content-Type': 'application/json',
        }
    }).then(res => res.json())
    .then((data)=>{return data})
    .catch((err)=>{console.log(err)})
}
export var deleteVideo = (data) => {
    return fetch(apiVideoData,{
        method: "DELETE",
        body: JSON.stringify(data),
        headers: {
            // 'Authorization': firebaseKey,
            'Content-Type': 'application/json',
        }
    }).then(res => res.json())
    .then((data) => { return data })
    .catch((err) => console.log(err));
}
export var getImageData = (data) => {
    return fetch(apiVideoData + "/get-image-data", {
        method: "POST",
        body: data
    }).then(res => res.json())
        .then((data) => { return data })
        .catch((err) => console.log(err));
}