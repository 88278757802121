import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardHeader, Container, Row } from "reactstrap";
import Header from "components/Headers/Header.js";
import TableContainer from 'components/TableContainer';
import ScheduleModal from "../components/Popup/NotificationDuration";
import { apiScheduleGet, apiScheduleAdd, apiScheduleUpdate } from '../services/Consts';

const Schedule = () => {
  const [scheduleModal, setScheduleModal] = useState(false);
  const [scheduleTime, setScheduleTime] = useState(""); 
  const [scheduleData, setScheduleData] = useState([]); // State to store schedule data
  const [editingNotification, setEditingNotification] = useState(null); // State for editing notification

  const toggleScheduleModal = () => setScheduleModal(!scheduleModal);

  const getCurrentSchedule = async () => {
    try {
      const response = await fetch(apiScheduleGet);
      const schedule = await response.json();
      setScheduleData(schedule || []);
    } catch (error) {
      console.error('Error fetching current schedule:', error);
    }
  };

  const saveSchedule = async (scheduleTime, title, message, screenname, imageUrl) => {
    const scheduleData = { scheduleTime, title, message, screenname, imageUrl };
    try {
      const method = editingNotification ? 'PUT' : 'POST';
      const url = editingNotification ? `${apiScheduleUpdate}/${editingNotification._id}` : apiScheduleAdd;
      const response = await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(scheduleData),
      });

      if (response.ok) {
        const updatedData = await response.json();
        setScheduleData((prevData) => {
          return editingNotification 
            ? prevData.map(item => item._id === editingNotification._id ? updatedData.data : item)
            : [...prevData, updatedData.data];
        });
        setScheduleModal(false);
        setEditingNotification(null);
      } else {
        console.error('Failed to save schedule:', response.statusText);
      }
    } catch (error) {
      console.error('Error saving schedule:', error.message);
    }
  };

  const handleEdit = (notification) => {
    setEditingNotification(notification);
    setScheduleModal(true);
  };

  useEffect(() => {
    getCurrentSchedule();
  }, []);

  const columns = useMemo(() => [
    {
      Header: "NO",
      accessor: "id", 
      Cell: (cellProps) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name text-center m-1">
            {cellProps.row.index + 1}
          </div>
        </div>
      ),
    },
    {
      Header: "Scheduletime",
      accessor: "Scheduletime",
      Cell: (cellProps) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name text-center m-1">
            {cellProps.row.original.Scheduletime}
          </div>
        </div>
      ),
    },
    {
      Header: "Title",
      accessor: "title",
      Cell: (cellProps) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name text-center m-1">
            {cellProps.row.original.title}
          </div>
        </div>
      ),
    },
    {
      Header: "Message",
      accessor: "message",
      Cell: (cellProps) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name text-center m-1 ">
            {cellProps.row.original.message}
          </div>
        </div>
      ),
    },
    {
      Header: "Navigating Screen",
      accessor: "screenname",
      Cell: (cellProps) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name text-center m-1">
            {cellProps.row.original.screenname}
          </div>
        </div>
      ),
    },
    {
        Header: "Apply Screen", 
        accessor: "navigatingScreen", 
        Cell: (cellProps) => {
          let title;
          switch (cellProps.row.index) {
            case 0:
              title = "FAQ"; 
              break;
            case 1:
              title = "SaveYourColorAgain"; 
              break;
            case 2:
              title = "SaveYourColor";  
              break;
            case 3:
              title = "ConnectedScreen";  
              break;
            default:
              title = "FAQ";  
              break;
          }
      
          return (
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 ms-2 name text-center m-1">
                {title}  {/* Display the appropriate title */}
              </div>
            </div>
          );
        },
      },
      
    {
      Header: "Image", 
      accessor: "imageUrl",
      Cell: (cellProps) => (
        <div className="d-flex align-items-center">
          {cellProps.row.original.imageUrl && (
            <img
              src={cellProps.row.original.imageUrl}
              alt="Notification Image"
              style={{
                width: '30px',
                height: '30px',
                borderRadius: '50%',
              }}
            />
          )}
        </div>
      ),
    },
    {
      Header: "Actions", 
      accessor: "actions", 
      Cell: (cellProps) => (
        <div className="d-flex align-items-center justify-content-center">
          {/* Edit Icon */}
          <i
            className="fa fa-edit"
            onClick={() => handleEdit(cellProps.row.original)} 
            style={{ cursor: 'pointer', marginRight: '10px' }}
          />
        </div>
      ),
    },
  ], [scheduleData]);

  return (
    <>
      <div>
        <ScheduleModal
          isOpen={scheduleModal}
          toggle={toggleScheduleModal}
          scheduleTime={scheduleTime}
          setScheduleTime={setScheduleTime}
          saveSchedule={saveSchedule}
          editingNotification={editingNotification}
        />

        <Header />
        <Container className="mt--7" fluid style={{ marginTop: '-8rem !important' }}>
          <Row>
            <div className="col">
              <Card className="shadow" style={{ height: '82vh' }}>
                <CardHeader className="border-0">
                  <h3 className="mb-2">Schedule Notifications</h3>
                  <div style={{ cursor: 'pointer', float: 'right', marginTop: 5 }}>
                    {/* <i onClick={() => setScheduleModal(true)} className="fa fa-plus pr-3" /> */}
                  </div>
                </CardHeader>
                <TableContainer
                  columns={columns}
                  data={scheduleData || []}
                  customPageSize={10}
                  className="custom-header-css"
                />
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Schedule;
